export function getTheme() {
  return {
    breakpoints: {
      xs: 1,
      sm: 768,
      md: 992,
      lg: 1200,
      xl: 1450,
    },

    colors: {
      slate1: '#191f2c',
      slate2: '#262f40',
      slate3: '#435570',
      slate4: '#4d5f80',
      slate5: '#8092b2',

      eb1: '#4a3bcf',
      eb2: '#a49de7',
      eb3: '#c9c4f1',

      blue: '#4A3BCF',
      white: '#fdfdff',
      whiteAlfa: 'rgba(255, 255, 255, 0.8)',
      red: '#f56565',
      lightRed: '#f56565',
      gray: '#a2aaad',
      teal: '#51c0ac',
      lightTeal: '#b4ede2',
      yellow: '#F2C94C',
    },
  };
}
